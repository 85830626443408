import './breadcrumbs.scss';

class Breadcrumbs {
    constructor (element) {
        this.$breadcrumbs = element;

        this.initialize();
    }

    initialize () {
        if (this.$breadcrumbs) {
            this.setShadows();
        }
    }

    setShadows () {
        const $breadcrumbList = this.$breadcrumbs.querySelector('.breadcrumbs__list');

        $breadcrumbList.addEventListener('scroll', () => {
            const scrollLeft = $breadcrumbList.scrollLeft;
            const scrollWidth = $breadcrumbList.scrollWidth;
            const listWidth = $breadcrumbList.offsetWidth;
            const endScroll = scrollWidth - listWidth - 10;
            if (scrollLeft > 10) {
                this.$breadcrumbs.classList.add('show-left-shadow');
            } else {
                this.$breadcrumbs.classList.remove('show-left-shadow');
            }

            if (scrollLeft > endScroll) {
                this.$breadcrumbs.classList.add('hide-right-shadow');
            } else {
                this.$breadcrumbs.classList.remove('hide-right-shadow');
            }
        }, { passive: true });
    }
}

export { Breadcrumbs };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $breadcrumbs = $context.querySelectorAll('[data-breadcrumbs="root"]');
        if ($breadcrumbs.length > 0) {
            for (let i = 0; i < $breadcrumbs.length; i++) {
                const breadcrumbsAPI = new Breadcrumbs($breadcrumbs[i]);
                $breadcrumbs[i].API = breadcrumbsAPI;
            }
        }
    }
});
